<template>
  <div>
    <div class="box">
      <div class="statistics flex">
        <!-- <div>全部1290</div> -->
        <div v-for="(item,index) in orderTask" :key="item.id" class="statisticss cur-p" @click="cleStatus(item)">
          <div :class="item.taskStatus === taskStatus?'changeStyle':''">{{ item.taskStatusName }} {{ item.num }}</div>
        </div>
      </div>
      <div>
        <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></ly-searchList>
      </div>
    </div>
    <div class="m-t1 box">
      <!-- <div style="padding:15px 0"><a-button @click="acceptOrders" class="m-l2" type="primary">接单</a-button></div> -->
      <div class="flex  titleBox  pos-rel">
        <div class="pos-abs" style="left:15px"> <a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange" /></div>
        <div class="info">任务信息</div>
        <div class="vehicle">任务车辆</div>
        <div class="demand">代收信息</div>
        <div class="send">派单价</div>
        <div class="status">状态</div>
        <!-- <div class="settlement">结算信息</div> -->
        <div class="add">操作</div>
      </div>
      <a-spin :spinning="loading">
        <div class="contentBox" v-if="list.length>0">
          <div v-for="item in list" :key="item.id" class="pos-rel itemBox" style=" padding: 10px 20px 10px 50px;">
            <div class="pos-abs flex al-center" style="height:100%;left:15px">
              <a-checkbox v-model:checked="item.checked"></a-checkbox>
            </div>
            <div class="m-b1 fz-12" style="color:#999">
              <span class="m-r1"> 派单人：{{ item.arranger }}</span>
              <span class="m-r1">派单时间：{{ item.scheduleTime }}</span>
              <span class="m-r1">最晚接单时间：{{ item.comReceivingTime }}</span>
              <span class="m-r1"> 规定完成时间：{{ item.comFinishTime }} </span>
              <span class="m-r1" v-if="item.taskStatus === 1||item.taskStatus === 2||item.taskStatus === 9"> 接单时间：{{ item.receivingTime }}</span>
              <span class="m-r1" v-if="item.taskStatus === 1||item.taskStatus === 2||item.taskStatus === 9"> 接单时效：{{ item.receivingPrescription }}</span>
              <span class="m-r1" v-if="item.taskStatus === 9"> 完成时间：{{ item.finishTime }}</span>
              <span class="m-r1" v-if="item.taskStatus === 9"> 完成时效：{{ item.finishPrescription }}</span>
            </div>
            <div class="order ">{{ item.taskId }}</div>
            <div class="flex">
              <div class="info">
                <div class="flex">
                  <div class="start">起</div>
                  <div>
                    <div>{{ item.lineStart }}</div>
                    <div>
                      <span v-if="item.pickAddress" class="m-r1">{{ item.pickAddress }}</span>
                      <span class="m-r1">{{ item.pickPerson }}</span>
                      <span>{{ item.pickerMobile }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex m-t1">
                  <div class="start" style="background:#D48310">终</div>
                  <div>
                    <div>{{ item.lineEnd }}</div>
                    <div>
                      <span v-if="item.deliveryAddress" class="m-r1">{{ item.deliveryAddress }}</span>
                      <span class="m-r1">{{ item.deliveryPerson }}</span>
                      <span>{{ item.deliveryMobile }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vehicle">
                <div v-if="item.vehicleList" class="flex-d ">
                  <div>共{{ item.vehicleList.length }}辆</div>
                  <div v-for="opt in item.vehicleList" :key="opt.id">
                    <div>{{ opt.vinNo }} <span class="m-l1">{{ opt.brand }}{{ opt.model }}</span></div>
                  </div>
                </div>
              </div>
              <div class="demand">
                <div class="flex-d al-center" v-if="item.isPeerCollection?.value === 1">代收金额：{{item.peerCollectionAmt}}<div><a @click="CollectionAdd(item)">代收码</a></div>
                </div>
              </div>
              <div class="send">总运费:￥{{item.taskAmt}}<span v-if="item.oilAmt">(含油卡：{{ item.oilAmt }})</span></div>
              <div class="status" :style="{color:item.taskStatus === 0 ? '#D48310' : '#396AFC'}">{{item.taskStatusName}}</div>
              <!-- <div class="settlement">缺少</div> -->
              <div class="add">
                <div v-if="item.taskStatus === 0">
                  <a-popconfirm title="确定接单吗?" ok-text="确定" cancel-text="取消" @confirm="acceptOrders(1,item)">
                    <div style="width:80px" class="m-b1"><a-button ghost type="primary" :loading="btnLoading">立即接单</a-button></div>
                  </a-popconfirm>
                  <div><a-button style="width:100%" ghost danger @click="refuse(item)" >拒绝</a-button></div>
                </div>
              </div>
            </div>
            <div class="m-t1 fz-12">任务段：{{item.taskName}}</div>
            <div class="m-t1 color-R">备注：{{ item.remark }}</div>
          </div>
        </div>

        <div v-if="list.length==0" style="padding: 50px 0;">
          <a-empty />
        </div>
      </a-spin>
      <div class="flex ju-end m-t2 m-r2">
        <a-pagination show-quick-jumper show-size-changer v-model:current="pagination.current" @showSizeChange="showSizeChange" :show-total="pagination.showTotal" :total="pagination.total" @change="pageChange" />
      </div>
      <div style="height:20px"></div>
    </div>
    <a-modal v-model:visible="visible" title="理由" footer="" destroyOnClose>
      <ly-formList ref="formListRef" :fromList="fromList" @cancel="visible = false" @getVal="submit"></ly-formList>
    </a-modal>
    <!-- 付款码 -->
    <PayModal ref="payQRCref" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { iptData, fromList } from './task'
import { orderTaskPage, orderTaskDoTask, orderTaskCount } from '@/api/transport/vehicle.js'
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
import { transitionTimeYMD } from '@/utils/util'
import PayModal from '../components/payModal'

const visible = ref(false)
const checkAll = ref(false)
const indeterminate = ref(false)
const loading = ref(false)
const list = ref([])
const remakeItem = ref({})
const searchMsg = ref({})
const orderTask = ref([])
const taskStatus = ref('')
const payQRCref = ref(null)
const btnLoading = ref(false)
const formListRef = ref(null)
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 10,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e, v) => {
  pagination.value.current = e
  pagination.value.pageSize = v
  reqOrderTaskPage()
}

// 代收码
const CollectionAdd = (item) => {
  let obj = {
    startAddress: item.lineStart,
    endAddress: item.lineEnd,
    taskId: item.taskId,
    orderId: item.orderId,
    payType: 1
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}

const cleStatus = (item) => {
  taskStatus.value = item.taskStatus
  searchMsg.value.taskStatus = item.taskStatus
  pagination.value.current = 1
  reqOrderTaskPage()
}

//批量接单
//拒绝
const refuse = (item) => {
  remakeItem.value = item
  visible.value = true
}
const submit = () => {
  acceptOrders(0, remakeItem.value)
}
const showSizeChange = (e, v) => {
  pagination.value.current = e
  pagination.value.pageSize = v
  reqOrderTaskPage()
}

const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.arrangedTimeStart = transitionTimeYMD(msg.arrangedTimeStart)
  msg.arrangedTimeEnd = transitionTimeYMD(msg.arrangedTimeEnd)
  msg.doneTimeStart = transitionTimeYMD(msg.doneTimeStart)
  msg.doneTimeEnd = transitionTimeYMD(msg.doneTimeEnd)
  msg.receivingTimeStart = transitionTimeYMD(msg.receivingTimeStart)
  msg.receivingTimeEnd = transitionTimeYMD(msg.receivingTimeEnd)
  msg.taskStatus = taskStatus.value
  pagination.value.current = 1
  searchMsg.value = msg
  reqOrderTaskPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  searchMsg.value.taskStatus = taskStatus.value
  reqOrderTaskPage()
}
const acceptOrders = (type, item) => {
 if(type === 1){
  btnLoading.value = true
 }
 if(formListRef.value){
  formListRef.value.fileLoading = true
 }
  orderTaskDoTask({
    taskId: item.taskId,
    type: type
  }).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    reqOrderTaskPage()
    if (type === 0) {
      visible.value = false
    }
  }).finally(() => {
    btnLoading.value = false
    if( formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}

const onCheckAllChange = (e) => {
  if (e.target.checked) {
    list.value.forEach(item => {
      item.checked = true
    })
  } else {
    list.value.forEach(item => {
      item.checked = false
    })
  }
  indeterminate.value = false
}
watch(() => list.value.map(item => item.checked), (val) => {
  checkAll.value = val.every(item => item)
  if (!checkAll.value) {
    indeterminate.value = val.some(item => item)
  } else {
    indeterminate.value = false
  }
})
const reqOrderTaskPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }
  Object.assign(msg, searchMsg.value)
  orderTaskPage(msg).then(res => {
    if (res.code !== 10000) return
    res.data.records.forEach(item => {
      item.checked = false
    })
    list.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}
const reqOrderTaskCount = () => {
  orderTaskCount().then(res => {
    if (res.code !== 10000) return
    orderTask.value = res.data
    console.log(res);
  })
}
onMounted(() => {
  reqOrderTaskPage()
  reqOrderTaskCount()
})
</script>

<style lang="less" scoped>
:deep(.ant-btn) {
  padding: 0 10px;
}
.itemBox {
  border-bottom: 1px solid rgb(217, 217, 217);
}
.start {
  width: 18px;
  height: 18px;
  background: #396afc;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-right: 5px;
}
.order {
  font-weight: 700;
  font-size: 16px;
  color: #191919;
  margin-bottom: 4px;
}
.statistics {
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 15px 10px;
}
.box {
  background: #fff;
}
.titleBox {
  padding: 10px 20px 10px 50px;
  background: rgb(242, 242, 242);
  width: 100%;
  color: #191919;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid rgb(238, 238, 238);
}
.contentBox {
  background: #fff;
  width: 100%;
}
.info {
  width: 27%;
}
.vehicle {
  width: 20%;
  display: flex;
  padding: 0 10px;
}
.demand {
  width: 14%;
  display: flex;
  padding: 0 10px;
}
.send {
  width: 14%;
  display: flex;
  padding: 0 10px;
}
.status {
  width: 10%;
  display: flex;
  padding: 0 10px;
}
.settlement {
  width: 14%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
.add {
  width: 14%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
.data-statistics {
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 0;
  transition: all 0.5s;
}

.statisticss {
  padding: 0px 10px;
}

.changeStyle {
  color: #396afc;
}
</style>